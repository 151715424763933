const get_started = {
    page_title: 'Rozpocznij',
    title: 'Coś do odkrycia, aby pomóc ci odnieść sukces',
    subtitle: 'Kilka rzeczy, które możesz zrobić, aby szybko uzyskać wartość Seitrace',
    develop: {
        title: 'Develop: Zajmie to 5 minut, aby zintegrować swoją aplikację',
        title_cloud: 'Develop: Bezpiecznie zintegruj swoją aplikację w kilka minut',
        subtitle_cloud: 'Lub poświęć 5 minut na integrację swojej aplikacji za pomocą naszych gotowych SDKów i samouczków.',
    },
    customize: {
        title: 'Customize: Dostarcz świetne doświadczenie logowania',
        preview: {
            title: 'Sprawdź podgląd na żywo doświadczenia logowania, które właśnie spersonalizowałeś',
            subtitle: 'Wypróbuj teraz Seitrace, aby zobaczyć, jak działa',
        },
        connector: {
            title: 'Dodaj więcej konektorów, aby obsługiwać różne metody logowania społecznego',
            subtitle: 'Wypróbuj logowanie bez hasła i włącz bezpieczne i bezproblemowe doświadczenie dla swoich klientów',
        },
        continue_customizing: 'Kontynuuj dostosowywanie',
        try_now: 'Wypróbuj teraz',
        add_more: 'Dodaj więcej',
    },
    secure: {
        title: 'Zabezpiecz: Chron swoje zasoby',
    },
    manage: {
        title: 'Zarządzaj: Określ kontrolę dostępu do swojego produktu i użytkowników',
        rbac: {
            title: 'Dodaj oparte na rolach zarządzanie dostępem, aby chronić swoje zasoby',
            subtitle: 'Kontroluj swoje zasoby za pomocą skalowalnej autoryzacji opartej na rolach dla różnych przypadków użycia',
        },
        create_roles: 'Twórz role',
    },
    view_all: 'Zobacz wszystkie →',
};
export default Object.freeze(get_started);
